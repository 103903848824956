import axios from 'axios';
import {Value_Default_i18n} from '@/model';
import {getT} from '../model/i18n/apiMessage';
import {getCookieValueByName} from '@/utils/tool';

import { Message, MessageBox } from 'element-ui';

export const HostConfig = {
    en: {
        test: 'http://staging-srv-intl.b.mi.com/api/',
        prod: 'https://srv.intl.b.mi.com/api/',

        href: {
            test: 'http://staging-global-dev.b.mi.com',
            prod: 'https://global.dev.b.mi.com',
        },
    },
    cn: {
        // 新版本接口
        test: 'https://staging-srv-new.b.mi.com/api/',
        prod: 'https://srv.b.mi.com/api/',
        // 旧版本接口（和手机激活接口是同一个服-待迁移）
        // test: 'https://staging-srv-new.b.mi.com/',
        // prod: 'https://srv.b.mi.com/',

        href: {
            test: 'https://new-staging-dev.b.mi.com',
            prod: 'https://dev.b.mi.com',
        },
    },
};

export const logoList = 'logo/list';

// 消息tongzh8i
export const devOperates = 'developer/getDevOperates';

// 阅读消息
export const readNews = 'developer/updateIsNew';

// 开发者状态 sign mid
export const userStatus = 'developer/judgeRegisterOrNot';

// 开发者基本信息 sign mid
export const userInfo = 'developer/getDeveloper';
// 更新开发者信息
/**
developerName: gg打野2
companyName: 山上1
businessLicense: 12312123
licensePicUrl: (binary)
address: 123
contactEmail: 32@qq.com
contactQq: 12233442132
miId: 3150276528
 */
export const updateInfo = 'developer/update';
// 注册开放者信息
export const registerInfo = 'developer/register';

// 测试证书 ?start=0&genrStatus=&sortType=1&limit=20&sign=76A410C8A8EBE37580409D1AD5F5A52C&userId
export const testList = 'bizMode/listCertificateApplies';
// 申请测试证书
/**
agencyName: 上传试试
imies: 1222,12222
miuiVersion: 1
sign: 5795EFB68CFB7B3035650A5DC56B5979
 */
export const postApplyTest = 'bizMode/applyCertificate';

// 隐私政策和应用列表是同一个接口 参数?start=0&applyStatus=&sortType=1&limit=20&sign
export const appList = 'bizMode/listCodeApplies';

// 开发者激活码信息
// rom数量 无参数
export const romCount = 'bizMode/surplusUnlockCount';
// 激活码列表 start limit sign
export const codeList = 'bizMode/listCodes';

// 设置邮箱地址
export const setEmail = 'bizMode/sendCodeMailToDev';
//

// 上传隐私 post file
export const postUploadPrivcy = 'bizMode/uploadPrivateFile';
// 上传apk
export const postUploadAPK = 'bizMode/uploadAndCheckApk';
export const postApplyCodee = 'bizMode/applyCode';

// 销售信息 start limit sign
export const cusList = 'developer/client/show';
// 信息状态标记
export const editCusStatus = 'developer/client/editResult';

// 旧平台老用户迁移
export const moveData = 'developer/moveData';

// 下载接口
export const bizDownload = 'bizMode/download';



function handleError(error, url = '') {
    if (error instanceof Error) {
        // todo 上报网络异常
        console.log('网络异常', url);
        console.error(error);
        if (axios.axoisVm) {
            axios.axoisVm.$message({type: 'error', message: error.message});
        }
    } else {
        console.log(JSON.stringify(error), url);
    }
}

let alertCount = 0;
if (process.env.NODE_ENV === 'development') {
    alertCount = 1;
}
function handleRes(res, path, userId) {
    const {code, desc} = res.data;
    // console.log('handleRes', url, getT(code), code);
   
    if (code === 201 || code === 401) {
        // 最外面会有判断，如果userId不存在，直接回提示登录，如果userId存在则提示过期
        // 因为接口会重写cookie 所以需要前置来取
        if (!userId) {
            return;
        }
        if (alertCount) {
            return;
        }
        alertCount = 1;
        MessageBox.alert(getT(code), '提示', {
            confirmButtonText: '确定',
            callback: () => {
                alertCount = 0;
                goIndexPage();
            },
        });
    }
    else if (code === 500) {
        Message({type: 'error', message: desc});
    } else if (code !== 200) {
        Message({type: 'error', message: getT(code) || desc});
    }
}

export function goIndexPage() {
    console.log('日志：回到首页');
    if (
        process.env.NODE_ENV !== 'development'
        && ['/index.html', '/'].indexOf(location.pathname) === -1
    ) {
        // 非开发环境
        location.href = getHref();
    }
}

export function get(url, config = {}) {
    const path = `${getHost()}${url}`;
    config.withCredentials = true;
    const userId = getCookieValueByName('userId');

    return axios
        .get(path, config)
        .then((res) => {
            if (!config.notDefaultHandle) {
                // 异常状态统一处理
                handleRes(res, path, userId);
            }
            return res.data;
        })
        .catch((error) => {
            handleError(error, path);
            // 通用error处理
            console.error('通用 error', error);
            throw error;
        });
}

export function post(url, data, config = {}) {
    const path = `${getHost()}${url}`;
    config.withCredentials = true;
    return axios
        .post(path, data, config)
        .then((res) => {
            if (!config.notDefaultHandle) {
                // 异常状态统一处理
                handleRes(res, path);
            }

            return res.data;
        })
        .catch((error) => {
            // 通用error处理
            handleError(error);
            console.error('通用 error', error);
            throw error;
        });
}

export function getLocale() {
    return Value_Default_i18n;
}

export function getHost(isHTTP) {
    const locale = getLocale();
    const config = HostConfig[locale] || HostConfig[Value_Default_i18n];
    if (isHTTP && process.env.NODE_ENV === 'development') {
        return 'https://staging-srv-new.b.mi.com/api/';
    }
    return config[process.env.VUE_APP_ENV] || '/api/';
}

export function getHref() {
    const locale = getLocale();
    const config = HostConfig[locale] || HostConfig[Value_Default_i18n];
    // console.log('processVUE_APP_ENV', process.env);
    if (process.env.NODE_ENV === 'development') {
        return 'https://new-staging-dev.b.mi.com';
    }
    return config.href[process.env.VUE_APP_ENV] || location.href;
}

export function getDocHtml() {
    return get('cms/content?platform=zhengqi-open-document');
}

export function getLoginoutAccount() {
    if (process.env.VUE_APP_ENV === 'prod') {
        return 'https://account.xiaomi.com';
    }

    return 'http://account.preview.n.xiaomi.net';
}

export function downLoadHasHttps(url = '') {
    if (url && url.match && url.match(/^https:\/\//)) {
        return true;
    }
    return false;
}

export function downLoadByServer(url = '') {
    if (!url || !url.match) {
        return '';
    }
    if (downLoadHasHttps(url)) {
        return url;
    }
    // 接口还未过来
    return `${getHost()}bizMode/download?fileUrl=${url}`;
}

export const deviceMap = {
    list: 'device/list',
    filingsDeviceList: 'device/dataPage',
    batchNo: 'device/batchNo',
    code: 'device/activeCode',
}

export const filingsMap = {
    list: 'recordation/list',
    add: 'recordation/add',
    detail: 'recordation/detailByRecordation',
    export: 'recordation/export',
    reback: 'recordation/withdrawRecord',
    deviceList: 'recordation/deviceList',

    getAddStatus: 'recordation/status',
}

export const codeOperateMap = {
    list: 'active/codeOperationPage',
    detail: 'active/operationDetail',
    recordDetail: 'active/codeOperationRecordPage',
    // 激活码详情
    codeDetail: 'rom/detail',
    bind: 'active/codeBind',
    unBind: 'active/codeUnBind',
}

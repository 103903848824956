<template>
    <div class="topbar wrap">
        <div class="container">
            <div class="topbar-nav">
                <template v-for="(item, index) in linkList">
                    <a :key="'topBar' + index" :href="item.link" target="_blank">
                        {{item.name}}
                    </a>
                </template>
                
            </div>
            <!-- 切换语言 -->
            <div class="language-box">
                <!-- <a @click="changeLanguage('cn')" class="btn-item">简体中文</a> -->
                <a href="https://dev.b.mi.com/" class="btn-item">简体中文</a>
                <a href="https://global.dev.b.mi.com/" class="btn-item">English</a>
            </div>
        </div>
    </div>
</template>

<script>
import { Value_Default_i18n} from '@/model';

export default {
    props: {
        renderStatus: {
            type: Number,
        },
    },
    data() {
        return {
            linkList: [],
        };
    },
    created() {
        this.updatedData();
    },

    methods: {
        updatedData() {
            this.linkList = [
                {
                    link: '//www.mi.com/index.html',
                    name: this.$t('common.topBar').linkMIStore,
                },
                {
                    link: 'https://www.miui.com/',
                    name: this.$t('common.topBar').linkMIUI,
                },
                {
                    link: 'https://i.mi.com/',
                    name: this.$t('common.topBar').linkMICloud,
                },
                // 死链接
                // {
                //     link: 'https://shuidi.mi.com/',
                //     name: this.$t('common.topBar').linkXiaoai,
                // },
                {
                    link: 'https://airstar.com/?from=micom',
                    name: this.$t('common.topBar').linkFinance,
                },
                {
                    link: 'https://youpin.mi.com/',
                    name: this.$t('common.topBar').linkYoupin,
                },
            ];
        },
        changeLanguage(type) {
            const locale = type || Value_Default_i18n;
            if (locale !== this.$i18n.locale) {
                this.$root.$i18n.locale = locale;
                this.$root.$emit('i18n-locale-change', {locale});
                this.updatedData();
            }
        },
    },
};
</script>

<style lang="less">
.topbar {
    background: #000;

    .container {
        background: #000;
        display: flex;
        justify-content: space-between;
        height: 40px;
        line-height: 40px;
        font-size: 12px;

        a {
            color: #b0b0b0;
            padding: 0 12px;
            cursor: pointer;

            &+a {
                border-left: 1px solid #424242;
            }
        }
    }
}
</style>

import {clearCookie} from './cookie-config';
import {
    getLocale,
    get,
    getHost,
    getHref,
    getLoginoutAccount,
    devOperates,
    readNews,
    userStatus,
    userInfo,
    updateInfo,
    registerInfo,
    post,
    testList,
    postApplyTest,
    appList,
    postUploadPrivcy,
    postUploadAPK,
    postApplyCodee,
    romCount,
    codeList,
    cusList,
    editCusStatus,
    moveData,
    setEmail,
    downLoadByServer,
    downLoadHasHttps,
    logoList,
    goIndexPage,
} from './api';
import {getCookieValueByName, generateSign} from '@/utils/tool';

export {goIndexPage};

export function getMitobBusiness() {
    let sid = 'mitob_business';

    if (getLocale() === 'en') {
        sid = 'mitob_business_sgp';
    }
    const key = `${sid}_ph`;
    const value = getCookieValueByName(key);

    return {
        sid,
        key,
        value,
    };
}

// export function postUpdate() {
//     return
// }

// 登录
export function login(callback = '') {
    // if (process.env.NODE_ENV !== 'development') {
        // 开发环境也应该调正确的测试链接
        if (callback.match && callback.match(/^\//)) {
            callback = location.origin + callback;
        }
        const loginUrl = `${getHost(true)}login/passport?callBack=${callback || getHref()}`;
        process.env.VUE_APP_ENV !== 'prod' && console.log('logign', callback, loginUrl);
        
        location.href = loginUrl;
        // return Promise.resolve({});
    // }
    // return initCookieConfig();
}

// 注册
export function register() {
    const {sid} = getMitobBusiness();
    const host = getHost(true);
    // 还未区分国内外
    const sign = process.env.VUE_APP_ENV === 'prod' ? 'hIdH+K4VKFH8q0030iApteuGMkI=' : 'BZ+JzN+STol9ebrLnmrD3SmXK1Y=';
    const registerUrl = `${
        getLoginoutAccount()
        // 'http://account.preview.n.xiaomi.net/pass/serviceLogin?'
    }/pass/register?callback=${encodeURIComponent(
        `${host}sts?sign=${encodeURIComponent(sign)}&followup=${
            // encodeURIComponent(`${'http://staging-srv-intl.b.mi.com/'}sts?sign=${encodeURIComponent('BCewjn7ww7SOZNU1M3yGHGoGD8I=')}&followup=${
            encodeURIComponent(
                `${host}api/login/passport?callBack=${
                    // encodeURIComponent(`${'http://staging-srv-intl.b.mi.com/'}developer/passport?callBack=${
                    getHref()
                    // encodeURIComponent('https://staging-global-dev.b.mi.com')
                }`,
            )
        }`,
    )}&sid=${sid}&_locale=zh_CN`;

    if (process.env.NODE_ENV !== 'development') {
        // if (process.env.VV === 'prod') {
        // }
        // else {

        // }
        location.href = registerUrl;

        return Promise.resolve({});
    }
    console.log('registerUrl', registerUrl);
    // return login();
}
// register()

// 登出
export function loginout() {
    if (process.env.NODE_ENV !== 'development') {
        const userId = getCookieValueByName('userId');
        const {sid} = getMitobBusiness();
        clearCookie();
        return (location.href = `${getLoginoutAccount()}/pass/logout?callback=${getHref()}&sid=${sid}&userId=${userId}`);
    }
    return clearCookie();
}

// 通知，代办
export function getDevOperates() {
    const userId = getCookieValueByName('userId');

    let sign = generateSign({
        miId: userId,
    });

    return get(devOperates, {
        params: {
            miId: userId,
            sign,
        },
    });
}
// 阅读通知
export function getReadNews(operateId) {
    const sign = generateSign({operateId});
    return get(readNews, {
        params: {
            operateId,
            sign,
        },
    });
}

// 用户状态，展示不同阶段的页面(用户状态从cookie中取)
export function getUserStatus(u, {notDefaultHandle} = {notDefaultHandle: false}) {
    const userId = getCookieValueByName('userId');
    let sign = generateSign({
        miId: userId,
    });

    return get(userStatus, {
        params: {
            miId: userId,
            sign,
        },
        notDefaultHandle,
    });
}

// 开发者状态，展示不同阶段的页面(用户状态从cookie中取)
export function getUserInfo() {
    const userId = getCookieValueByName('userId');
    let sign = generateSign({
        miId: userId,
    });

    return get(userInfo, {
        params: {
            miId: userId,
            sign,
        },
    });
}

// 开发者信息更新
export function updateUserInfo({
    developerName = 'gg打野2',
    companyName = '山上1',
    businessLicense = '12312123',
    licensePicUrl = null,
    address = '123',
    contactEmail = '32@qq.com',
    contactQq = '12233442132',
    isNew = 0,
    developerPhone = '',
} = {}) {
    const userId = getCookieValueByName('userId');
    const formData = new FormData();
    formData.append('developerName', developerName);
    formData.append('companyName', companyName);
    formData.append('businessLicense', businessLicense);
    formData.append('file', licensePicUrl || new File([], '', {type: 'application/octet-stream'}));
    formData.append('address', address);
    formData.append('contactEmail', contactEmail);
    formData.append('contactQq', contactQq);
    formData.append('miId', userId);
    formData.append('developerPhone', developerPhone);

    const {key: mitobBusKey, value: mitobBusinessPh} = getMitobBusiness();

    const formObj = {
        developerName,
        companyName,
        businessLicense,
        address,
        contactEmail,
        contactQq,
        miId: userId,
        developerPhone,
    };
    formObj[mitobBusKey] = mitobBusinessPh;

    return post(
        `${isNew ? registerInfo : updateInfo}?sign=${generateSign(formObj)}&${mitobBusKey}=${mitobBusinessPh}`,
        formData,
        {
            withCredentials: true,
            crossDomain: true,
            headers: {},
        },
    );
}

// 和内置的uuid不一样
const ConfigUuid = '5cdd8678-cddf-4269-ab73-48387445bba8';
export function getTestList({page = 1, status = '', sortType = 1, max = 20} = {}) {
    const params = {
        current: page,
        size: max,
        genrStatus: status,
        sortType: sortType,
    };
    console.log(params);
    const sign = generateSign(params, ConfigUuid);
    params.sign = sign;
    return get(testList, {
        params,
    });
}
// 申请测试证书
export function postApplyTestCerty({agencyName, imies, miuiVersion = 1} = {}, {isHandle = 0} = {}) {
    const {key, value} = getMitobBusiness();
    const params = {
        agencyName,
        imies,
        miuiVersion,
    };
    params[key] = decodeURIComponent(value);
    const sign = generateSign(params, ConfigUuid);
    const formData = new FormData();
    formData.append('agencyName', agencyName);
    formData.append('imies', imies);
    formData.append('miuiVersion', miuiVersion);
    formData.append('sign', sign);

    return post(`${postApplyTest}?${key}=${value}`, formData, {isHandle});
}
// 应用列表
export function getAppList({page = 1, status = '', sortType = 1, max = 20} = {}) {
    const params = {
        current: page,
        size: max,
        applyStatus: status,
        sortType: sortType,
    };
    console.log(params);
    const sign = generateSign(params, ConfigUuid);
    params.sign = sign;
    return get(appList, {
        params,
    });
}

// 申请应用
export function postApplyAppCode({agencyName, packageUrl, packageUrl2, privacyPolicy, miuiVersion = 1} = {}) {
    const {key, value} = getMitobBusiness();
    const params = {
        agencyName,
        packageUrl,
        packageUrlV2: packageUrl2,
        privacyPolicy,
        miuiVersion,
    };
    params[key] = decodeURIComponent(value);
    const sign = generateSign(params, ConfigUuid);
    const formData = new FormData();
    formData.append('agencyName', agencyName);
    formData.append('packageUrl', packageUrl);
    formData.append('packageUrlV2', packageUrl2);
    formData.append('privacyPolicy', privacyPolicy);
    formData.append('miuiVersion', miuiVersion);
    formData.append('sign', sign);

    return post(`${postApplyCodee}?${key}=${value}`, formData);
}

export function uploadFileByApp(url, file) {
    // 单纯的文件上传
    const {key, value} = getMitobBusiness();

    const formData = new FormData();
    formData.append('file', file);
    return post(`${url}?${key}=${value}`, formData);
}
// 上传apk 废弃-使用组件自动上传
export function uploadAPK(file) {
    return uploadFileByApp(postUploadAPK, file);
}
// 输出上传文件的链接
export function getUploadAPKUrl() {
    const {key, value} = getMitobBusiness();
    return `${getHost()}${postUploadAPK}?${key}=${value}`;
}

// 上传隐私政策 废弃
export function uploadPervcy(file) {
    return uploadFileByApp(postUploadPrivcy, file);
}

// 输出上传隐私政策的链接
export function getUploadPervcyUrl() {
    const {key, value} = getMitobBusiness();
    return `${getHost()}${postUploadPrivcy}?${key}=${value}`;
}

// 激活码次数
export function getCodeCount() {
    return get(romCount);
}

export function getCodeList({page = 1, max = 20} = {}) {
    const params = {
        current: page,
        size: max,
    };
    const sign = generateSign(params, ConfigUuid);

    params.sign = sign;

    return get(codeList, {
        params,
    });
}

export function getCusList({page = 1, status = '', result = 3, order = 'desc', max = 20} = {}) {
    const params = {
        current: page,
        size: max,
        status,
        result,
        order,
    };

    const sign = generateSign(params, ConfigUuid);
    params.sign = sign;

    return get(cusList, {
        params,
    });
}

export function postCusStatus({id, result, reason = ''} = {}) {
    const {key, value} = getMitobBusiness();
    const formData = new FormData();
    formData.append('id', id);
    formData.append('result', result);
    formData.append('reason', reason);
    return post(`${editCusStatus}?${key}=${value}`, formData);
}

// 迁移旧平台的用户
export function getMoveData() {
    const miId = getCookieValueByName('userId');
    const sign = generateSign({miId});
    return get(moveData, {
        params: {
            sign,
            miId,
        },
    });
}

// 设置邮箱
export function getSetEmail({applyId, contactEmail}) {
    const sign = generateSign({applyId, contactEmail}, ConfigUuid);
    return get(setEmail, {
        params: {
            sign,
            applyId,
            contactEmail,
        },
    });
}

export {clearCookie, getHost, downLoadByServer, downLoadHasHttps};

//增加次数
export const addActiveCodeTimes = (params) => {
    const {key, value} = getMitobBusiness();
    return post(`bizMode/addActiveCodeTimes?${key}=${value}`, params);
};

// apk自助服务
export const getAgencyList = (params) => {
    return get(`apkSelf/getAgencyList`, {params});
};

export const checkAgencyName = (params) => {
    return get(`apkSelf/checkAgencyName`, {params});
};

//申请记录列表
export const getApplyHistory = (params) => {
    return get(`apkSelf/getApplyHistory`, {params});
};
//详情
export const getAgencyDetails = (params) => {
    return get(`apkSelf/getAgencyDetail`, {params});
};
//新增
export const addSelfApkInfo = (params) => {
    const {key, value} = getMitobBusiness();
    return post(`apkSelf/addSelfApkInfo?${key}=${value}`, params);
};

// //病毒扫描
export const apkVirusDetection = (params) => {
    const {key, value} = getMitobBusiness();
    return post(`apkSelf/apkVirusDetection?${key}=${value}`,
    params,
    {params}
    );
};

// //变更
export const updateApply = (params) => {
    const {key, value} = getMitobBusiness();
    return post(
        `apkSelf/updateApply?${key}=${value}`,
        params)
};
// 撤销
export const cancelApply = (params) => {
    const {key, value} = getMitobBusiness();
    return post(`apkSelf/cancelApply?${key}=${value}`, params, {params})
};

export const uploadPreset = (params) => {
    const {key, value} = getMitobBusiness();
    return post(
        `apkSelf/uploadFile?${key}=${value}`,
        params,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
};

export function getLogoList() {
    return get(logoList);
}

export function getRomList(params = {current: 1, size: 20}) {
    // const {key, value} = getMitobBusiness();
    const sign = generateSign({}, ConfigUuid);
    return get(`rom/list?sign=${sign}`, {params: params})

    // {
    //     code: 200,
    //     desc: "success",
    //     data: [
    //     {
    //     code: "2vb5********6v5u",
    //     createTime: "2022-03-22 15:02:12",
    //     usedCount: 0,
    //     remainingCount: 100,
    //     status: "1"
    //     }
    //     ]
    //     }
}


export const oneTrackConfig = {
    appId: '31000000596',
    region: 'CN',
};

export const sentryConfig = {
    appId: 'e8cbed234db24a4182bab1759b8a2708',
    url: 'https://f4326807b91645ad8c3bd5da014a6dab@sentry.d.xiaomi.net/552',
};

export const apmConfig = {
    group: 'mi-tob-dev',
    serverUrl: '//apm-rum.inf.miui.com',
    serviceName: 'migration-web-dev-cn',
    serviceVersion: process.env.CI_PIPELINE_ID,
    environment: process.env.APM_ENVIRONMENT,

};
import {MessageBox } from 'element-ui';
import {login, register} from '@/api';
import {getCookieValueByName} from './tool';

export function ifLogin() {
     
    const userId = getCookieValueByName('userId');

    if (!userId) {
        // // 未在登录状态，直接返回至首页
        // return location.href = '/';
        MessageBox.confirm('请您登录/注册后查看', '提示', {
            distinguishCancelAndClose: true,
            confirmButtonText: '登录',
            cancelButtonText: '注册',
            closeOnClickModal: false,
            showClose: false,
            closeOnHashChange: false,
          })
            .then(() => {
                // 去登录
                console.log('日志： 去登录');
                const href = `${
                    process.env.NODE_ENV === 'development' ?
                       'https://new-staging-dev.b.mi.com' : location.origin
                }${location.pathname}`;
                login(href);
            })
            .catch(action => {
              
              // 去注册
              console.log('日志： 去注册', action === 'cancel')
                action === 'cancel' && register(location.href);
            });
        
    }
    return !!userId;
}

import {oneTrackConfig} from './config';
import { getTiming } from './performance';

// 如果需要使用上报，则需要引入onetrack.js
// <script async src="//ssl-cdn.static.browser.mi-img.com/mistat-data/onetrack/onetrack.js"></script>

// 注册上报接口
window.onetrack = window.onetrack || function() {
    (window.onetrack.q = window.onetrack.q || []).push(arguments);
}
if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_ENV === 'prod') {
    localStorage.removeItem('_onetrack_test');
}
else {
    localStorage.setItem('_onetrack_test', 1);
}

// 上报初始化
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development') {
    const {appId, region} = oneTrackConfig;
    window.onetrack('init', appId, {region});
}



/**
 * 
 * @param {*} type 
 * @param {*} eventName 
 * @param {*} params 
 * @returns onetrackend
 * 文档地址： http://doc.t.pt.miui.com/onetrack/docs/sdk/web_sdk.html
 */
export function trackSend(type, eventName, params) {
    return window.onetrack(type, eventName, params)
}

getTiming(params => {
    params.routeName = window.location && window.location.hash || '/';
    trackSend('trackPage', params);
});

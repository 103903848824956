<template>
    <div class="footer wrap">
        <div class="content continer">
            <div class="items">
                <div class="item">
                    <h2>{{$t('common.bottom.helpTitle')}}</h2>
                    <p><a class="text-a" @click="toLinkByLogin('/download.html')">{{$t('common.bottom.helpDownload')}}</a></p>
                    <!-- <p><a href="javascript:void(0)" class="uploadSDK">Downloads</a></p> -->
                    <p><a href="/document.html">{{$t('common.bottom.helpFAQ')}}</a></p>
                    <p><a href="/document.html">{{$t('common.bottom.helpDoc')}}</a></p>
                    <!-- <p><a href="./document.html">Documentation</a></p> -->
                </div>
                <div class="item">
                    <h2>{{$t('common.bottom.linkTitle')}}</h2>
                    <p><a href="https://dev.mi.com/console/cloud/" target="_blank">{{$t('common.bottom.linkMICloud')}}</a></p>
                    <p><a href="https://dev.mi.com/console/" target="_blank">{{$t('common.bottom.linkPlatform')}}</a></p>
                    <!-- 死链接 -->
                    <!-- <p><a href="https://shuidi.mi.com/" target="_blank">{{$t('common.bottom.linkFinance')}}</a></p> -->
                    <p><a href="https://www.mi.com/index.html" target="_blank">{{$t('common.bottom.linkYoupin')}}</a></p>
                    <p>
                        <a href="https://cnbj6.cloud.mi.com/" target="_blank"
                            >{{$t('common.bottom.linkEcoCloud')}}</a
                        >
                    </p>
                </div>
                <div class="item">
                    <h2>{{$t('common.bottom.contactTitle')}}</h2>
                    <p>{{$t('common.bottom.contactEmail')}}</p>
                    <p>miui-enterprise-dev@xiaomi.com</p>
                </div>
                <div class="item min-width">
                    <h2>{{$t('common.bottom.followTitle')}}</h2>
                    <p><a href="https://weibo.com/xiaomikeji?is_hot=1" target="_blank">{{$t('common.bottom.followWeibo')}}</a></p>
                    <p class="img-box">
                        <a href="javascript:void(0)" id="wechatPublic">{{$t('common.bottom.followWeChat')}}</a>
                        <!-- <div id="wechatPic"></div> -->
                        <img class="wechat-img" src="@/assets/images/wechatPublick.jpg" alt="WeChat" />
                    </p>
                    
                </div>
            </div>
            <img class="slogan" :class="$i18n.locale === 'en' ? 'opacity-hide' : ''" src="@/assets/images/slogan.svg" alt="slogan" />
            <div class="info">
                <p>{{$t('common.bottom.ICP1')}}</p>
                <p>{{$t('common.bottom.ICP2')}}</p>
                
            </div>
        </div>
    </div>
</template>

<script>
import {login} from '@/api';
import {getCookieValueByName} from '@/utils/tool';

export default {
    methods: {
        toLinkByLogin(link) {
            if (getCookieValueByName('userId')) {
                return location.href = link;
            }
            login(link);
            // this.$myConfirm(getT('unLogin'));
        },
    }
};
</script>

<style lang="less">
.footer {
    min-width: 1420px;
    width: 100%;
    background: #35373f;
    padding-bottom: 10px;

    .items {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 42px 80px;
        border-bottom: 1px solid #4b4b4b;
        color: #fff;

        .item {
            width: 360px;

            &.min-width {
                width: 180px;
            }
        }

        a,
        p {
            font-size: 14px;
            color: #8e8e8e;
            line-height: 33px;
        }

        h2 {
            line-height: 22px;
            height: 22px;
            margin: 13px 0 26px;
            font-size: 16px;
        }

        p {
            position: relative;
            &:hover {
                .wechat-img,
                &.img-box::after {
                    display: block;
                }
            }

            &.img-box::after {
                position: absolute;
                left: 90px;
                top: 6px;
                z-index: 99;
                content: "";
                display: none;
                height: 0;
                width: 0;
                transform: rotate(45deg);
                border: 10px #fff solid;

            }
            

            .wechat-img {
                position: absolute;
                left: 100px;
                top: 50%;
                transform: translateY(-50%);
                display: none;
                width: 150px;
                height: 150px;
                border-radius: 8px;
                z-index: 999;

                
            }
        }

    }

    .slogan {
        display: block;
        margin: 60px auto 30px;

        &.opacity-hide {
            visibility: hidden;
        }
    }

    .info {
        display: block;
        margin: 0 auto;
        width: 720px;
        font-size: 12px;
        color: #8e8e8e;
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px;
    }

    .text-a {
        cursor: pointer;
    }
}

</style>

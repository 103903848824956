export const message = {
    en: {
        topBar: {
            linkMIStore: 'Mi Store',
            linkMIUI: 'MIUI',
            linkIoT: 'IoT',
            linkMICloud: 'Mi Cloud',
            linkXiaoai: 'Xiaoai',
            linkFinance: 'Finance',
            linkYoupin: 'Youpin',
        },
        head: {
            linkHome: 'Home',
            linkDoc: 'Documentation',
            linkDownload: 'Downloads',
            linkIoT: 'IoT Developer',
            linkToBMI: 'Mi Enterprise Services',
            btnLogin: 'Sign in',
            btnRegister: 'Sign up',
            btnLoginout: 'Exit',
            btnChangeinfo: 'Management',
        },
        bottom: {
            helpTitle: 'Help & Support',
            helpDownload: 'Downloads',
            helpFAQ: 'FAQ',
            helpDoc: 'Documentation',

            linkTitle: 'Most visited websites',
            linkMICloud: 'Mi Open Cloud',
            linkPlatform: 'Mi Open Platform',
            linkFinance: 'Mi AI Open Platform',
            linkYoupin: 'Mi Store',
            linkEcoCloud: 'Mi Eco Cloud',

            contactTitle: 'Contact us',
            contactEmail: 'Email',

            followTitle: 'Follow us',
            followWeibo: 'Sina Weibo',
            followWeChat: 'WeChat',

            ICPTitle: '',
            ICP1: 'Xiaomi Technology Co., Ltd. Beijing ICP Filing 10046444 Beijing Computer Information Network and Internet Registration No.11010802020134             All data listed on this website is provided by our lab unless otherwise specified.',
            ICP2: 'Xiaomi Technology Co., Ltd. Beijing ICP Filing 10046444 Beijing Computer Information Network and Internet Registration No.11010802020134             All data listed on this website is provided by our lab unless otherwise specified.',
        },
        weChat: 'Scan the QR code with WeChat to get more service info',

        notice: 'Notice',
        btnEnter: 'OK',
        btnClose: 'Cancel',
        messageTitleCus: 'Customer leads',
        messageTextCus: 'Mark as Discard,Enter the reason (up to 50 characters)',
        messageTitleCode: 'Send via email',
        messageTextCode: 'Enter email address',
        inputErrorMessage: 'Please enter the correct email format.',

        messageSuccess: 'Set successfully.',

        messageTitleCodeCount: 'Notice',
        messageTextCodeCount: 'Submitted the application successfully. Once it is approved, you will receive a notification email.',

        messageTextAddTimes: `Use the email address paired by the developer account to send emails to the Xiaomi Enterprise Developer Services. It's necessary to explain the how many times to increase and the reason for the application. Email address: miui-enterprise-dev@xiaomi.com`,
        messageTitleAddTimes: 'Increase times',


        
    },
    cn: {
        topBar: {
            linkMIStore: '小米商城',
            linkMIUI: 'MIUI',
            linkIoT: 'Mi Store',
            linkMICloud: '云服务',
            linkXiaoai: '小爱',
            linkFinance: '金融',
            linkYoupin: '有品',
        },
        head: {
            linkHome: '首页',
            linkDoc: '文档',
            linkDownload: '下载',
            linkIoT: 'IoT 开发者',
            linkToBMI: '政企门户',
            btnLogin: '登录',
            btnRegister: '注册',
            btnLoginout: '退出',
            btnChangeinfo: '控制台',
        },
        bottom: {
            helpTitle: '帮助与支持',
            helpDownload: '下载',
            helpFAQ: '常见问题',
            helpDoc: '文档中心',

            linkTitle: '常用平台',
            linkMICloud: '小米开放云',
            linkPlatform: '小米开放平台',
            linkFinance: '小米水滴平台',
            linkYoupin: '小米商城',
            linkEcoCloud: '小米生态云',

            contactTitle: '联系方式',
            contactEmail: '邮箱',

            followTitle: '联系我们',
            followWeibo: '新浪微博',
            followWeChat: '微信公众号',

            ICPTitle: '',
            ICP1: '小米科技 京ICP备10046444号 京公网安备11010802020134号',
            ICP2: '本网站所列数据，除特殊说明，所有数据均出自我司实验室测试',
        },
        weChat: 'Scan the QR code with WeChat to get more service info',
        notice: '提示',
        btnEnter: '确 定',
        btnClose: '取 消',
        messageTitleCus: '客户线索',
        messageTextCus: '标记为放弃,请填写放弃原因，50字以内',
        messageTitleCode: '发送至邮箱',
        messageTextCode: '请填写邮箱地址',
        inputErrorMessage: '请输入正确格式的邮箱。',
        messageSuccess: '设置成功。',
        messageTitleCodeCount: '提示',
        messageTextCodeCount: '提交申请成功。请耐心等待审核，审核通过后，您将收到我们下发的通知邮件，请注意查收。',

        messageTextAddTimes: `发邮件增加: miui-enterprise-dev@xiaomi.com`,
        messageTitleAddTimes: '增加次数',
    },
};

export default message;

<template>
    <div class="wrap">
        <TopBar v-if="params.topbar" />
        <NavTop :hasTopbar="params.topbar" :updateMessageStatus="updateMessageStatus" @messageList="list => $emit('messageList', list)" />
        <slot name="default"></slot>
        <Footer v-if="isFooter" />
        <Wechat v-if="false" />
        <Confirm
            :message="confirmMessage.message"
            :title="confirmMessage.title"
            :confirmButtonText="confirmMessage.confirmButtonText"
            :cancelButtonText="confirmMessage.cancelButtonText"
            @confirmCallback="confirmCallback"
            v-if="isConfirmShow"
        >
            <template v-if="confirmDefaultSlot">
                {{ confirmDefaultSlot }}
            </template>
        </Confirm>
    </div>
</template>

<script>
import TopBar from '@/components/layout/topBar';
import NavTop from '@/components/layout/navTop';
import Footer from '@/components/layout/footer';
import Wechat from '@/components/layout/wechat';
import Confirm from '@/components/common-ui/confirm';

export default {
    props: {
        params: {
            default() {
                return {
                    topbar: true,
                }
            }
        },
        isFooter: {
            type: Boolean,
            default: true,
        },
        updateMessageStatus: {
            type: Number,
        },
    },
    data() {
        return {
            confirmMessage: {},
            // vNode
            confirmDefaultSlot: '',
            isConfirmShow: false,
        };
    },
    components: {
        TopBar,
        NavTop,
        Footer,
        Wechat,
        Confirm,
    },
    created() {
        this.$initShowBox(this);
    },

    methods: {
        boxShow(message, title, {confirmButtonText, cancelButtonText, messageSlotFn}) {
            this.isConfirmShow = true;
            this.confirmMessage = {
                message,
                title,
                confirmButtonText,
                cancelButtonText,
            };

            if (typeof messageSlotFn === 'function') {
                this.confirmDefaultSlot = messageSlotFn();
            }
        },
        confirmCallback(close, enter) {
            this.confirmChangeType = close || enter;
            this.isConfirmShow = false;
        },
    }
};
</script>

<style lang="less">
@import url('@/assets/css/theme/index.less');
@import url('@/assets/css/reset.less');
@import url('@/assets/css/font/iconfont');

@viewWidth: 1400px;
.wrap {
    min-width: @viewWidth;
    .container {
        width: @viewWidth;
        margin: 0 auto;
        
    }
}

</style>

// 工具方法
import md5 from 'js-md5';
import configCookie from './config-cookie';

export function getCookieValueByName(name = '') {
    let value = '';
    if (name) {
        const cookieString = process.env.NODE_ENV === 'development' ? configCookie.cookie : document.cookie;
        const targetStringList = cookieString.split(';').filter(i => {
            return i.indexOf(name) !== -1;
        });
        if (targetStringList.length) {
            for (let i = 0;i<targetStringList.length; i++) {
                const mList = targetStringList[i].match(/=(.+)/);
                if (mList && mList[1]) {
                    value = mList[1];
                    break;
                }
            }
            
            
        }

        return encodeURIComponent(value);
    }
    return value;

}




const uuid = '5cda8678-cddf-3569-ab73-48387445as5d'

function b64EncodeUnicode(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
    return String.fromCharCode('0x' + p1);
  }));
}

export const generateSign = (params, myUuid) => {
  let paramsArr = Object.keys(params)
  let signStr = ''
  paramsArr = paramsArr.sort()
  paramsArr.forEach(item => {
    signStr += `${item}=${params[item]}&`
  })
  signStr = md5(b64EncodeUnicode(signStr + (myUuid || uuid)))
  return signStr.toLocaleUpperCase()
}

// todo-func
! function (a) {
    var b = /iPhone/i,
        c = /iPod/i,
        d = /iPad/i,
        e = /(?=.*\bAndroid\b)(?=.*\bMobile\b)/i,
        f = /Android/i,
        g = /(?=.*\bAndroid\b)(?=.*\bSD4930UR\b)/i,
        h =
            /(?=.*\bAndroid\b)(?=.*\b(?:KFOT|KFTT|KFJWI|KFJWA|KFSOWI|KFTHWI|KFTHWA|KFAPWI|KFAPWA|KFARWI|KFASWI|KFSAWI|KFSAWA)\b)/i,
        i = /IEMobile/i,
        j = /(?=.*\bWindows\b)(?=.*\bARM\b)/i,
        k = /BlackBerry/i,
        l = /BB10/i,
        m = /Opera Mini/i,
        n = /(CriOS|Chrome)(?=.*\bMobile\b)/i,
        o = /(?=.*\bFirefox\b)(?=.*\bMobile\b)/i,
        p = new RegExp("(?:Nexus 7|BNTV250|Kindle Fire|Silk|GT-P1000)", "i"),
        q = function (a, b) {
            return a.test(b)
        },
        r = function (a) {
            var r = a || navigator.userAgent,
                s = r.split("[FBAN");
            return "undefined" != typeof s[1] && (r = s[0]), s = r.split("Twitter"), "undefined" != typeof s[
                1] && (r = s[0]), this.apple = {
                    phone: q(b, r),
                    ipod: q(c, r),
                    tablet: !q(b, r) && q(d, r),
                    device: q(b, r) || q(c, r) || q(d, r)
                }, this.amazon = {
                    phone: q(g, r),
                    tablet: !q(g, r) && q(h, r),
                    device: q(g, r) || q(h, r)
                }, this.android = {
                    phone: q(g, r) || q(e, r),
                    tablet: !q(g, r) && !q(e, r) && (q(h, r) || q(f, r)),
                    device: q(g, r) || q(h, r) || q(e, r) || q(f, r)
                }, this.windows = {
                    phone: q(i, r),
                    tablet: q(j, r),
                    device: q(i, r) || q(j, r)
                }, this.other = {
                    blackberry: q(k, r),
                    blackberry10: q(l, r),
                    opera: q(m, r),
                    firefox: q(o, r),
                    chrome: q(n, r),
                    device: q(k, r) || q(l, r) || q(m, r) || q(o, r) || q(n, r)
                }, this.seven_inch = q(p, r), this.any = this.apple.device || this.android.device || this
                    .windows.device || this.other.device || this.seven_inch, this.phone = this.apple.phone || this
                        .android.phone || this.windows.phone, this.tablet = this.apple.tablet || this.android.tablet ||
                        this.windows.tablet, "undefined" == typeof window ? this : void 0
        },
        s = function () {
            var a = new r;
            return a.Class = r, a
        };
    "undefined" != typeof module && module.exports && "undefined" == typeof window ? module.exports = r :
        "undefined" != typeof module && module.exports && "undefined" != typeof window ? module.exports = s() :
            "function" == typeof define && window.define.amd ? window.define("isMobile", [], a.isMobile = s()) : a.isMobile = s()
}(window);


(function () {
    var MOBILE_SITE = 'https://b.mi.com/#/developer?refer=' + document
        .referrer, // site to redirect to
        NO_REDIRECT = 'noredirect'; // cookie to prevent redirect

    // I only want to redirect iPhones, Android phones and a handful of 7" devices
    if (window.isMobile.any) {

        // Only redirect if the user didn't previously choose
        // to explicitly view the full site. This is validated
        // by checking if a "noredirect" cookie exists
        if (document.cookie.indexOf(NO_REDIRECT) === -1 && !(location.href.match('knowledge.html'))) {
            document.location = MOBILE_SITE;
        }
    }
})();

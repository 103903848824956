const startTime = new Date();
export function getTiming(callback) {
    setTimeout(() => {
        if (document.readyState === 'complete') {
            return readyHandle(callback);
        }
        document.addEventListener('readystatechange', () => readyHandle(callback));
    });


}
function readyHandle(callback) {
    if (document.readyState !== 'complete') {
        return false;
    }
    if (window.performance && window.performance.getEntriesByType) {
        return getPerformanceByType(callback);
    }

    const duration = new Date() - startTime;
    return callback({
        dns: duration,
        tcp: duration,
        fByte: duration,
        aByte: duration,
        fp: duration,
        fcp: duration,
        domRead: duration,
        domTimer: duration,
        duration: duration,
    });
}

function getPerformanceByType(callback) {
    setTimeout(() => {
        // document.addEventListener('do')
        const [fpEntry = {startTime: 0}, fcpEntry = {startTime: 0}] = performance.getEntriesByType('paint');
        const [navigationEntry = {}] = performance.getEntriesByType('navigation');

        const navigationStart = 0;
        const {
            // dns解析时间
            domainLookupStart,
            domainLookupEnd,
            // tcp
            connectStart,
            connectEnd,
            // 返回
            responseStart,
            responseEnd,
            // dom
            domContentLoadedEventStart,
            domContentLoadedEventEnd,
            // complate
            // loadEventStart,
            loadEventEnd,

        } = navigationEntry;
        // dns 解析时间
        const dns = Math.round(domainLookupEnd - domainLookupStart);
        // tcp链接时间
        const tcp = Math.round(connectEnd - connectStart);
        // 第一个字节返回时间
        const fByte = Math.round(responseStart - navigationStart);
        // 所有请求时间
        const aByte = Math.round(responseEnd - responseStart);
        // 白屏时间
        const fp = Math.round(fpEntry.startTime);
        // 有效绘制时间(首屏)
        const fcp = Math.round(fcpEntry.startTime);
        // 可交互时间
        const domRead = Math.round(domContentLoadedEventEnd - navigationStart);
        // dom耗时
        const domTimer = Math.round(domContentLoadedEventEnd - domContentLoadedEventStart);
        // 总耗时
        const duration = Math.round(loadEventEnd - navigationStart);

        return callback({
            dns,
            tcp,
            fByte,
            aByte,
            fp,
            fcp,
            domRead,
            domTimer,
            duration,
        });
    });
}


import {Value_Default_i18n} from '@/model';

export const message = {
    en: {
        201: 'Cookie verification failed. Please log in again and try again.',
        401: 'Your sign in status has expired. Please sign in and try again.',
        500: 'Network error, please try again later.',
        501: 'Illegal request, no permission!',
        other: 'The network is abnormal. Please try again later.',
        unLogin: 'Please log in and try again.',
        submitSuccess: 'Submitted successfully!',
        groupMaxMesage: 'Add up to 5 groups',
        certyNameMessage: 'Enter institution name',
        certyIMEIMessage: 'Complete IMEI, each group contains 2 IMEIs',
        uploadSuccess: 'upload sucess！',
        uploadWarn: 'Complete the info',
    },
    cn: {
        201: 'Cookie验证失败，请您重新登录后再试。',
        401: '您的登录状态已过期，请登录后再试。',
        500: '网络错误了，请稍后再试',
        501: '非法请求，没有权限！',
        other: '网络异常了，请稍后后再试。',
        unLogin: '您还未登陆，请登录后再试。',
        submitSuccess: '信息提交成功！',
        groupMaxMesage: '最多可以添加5组',
        certyNameMessage: '请填写机构名称',
        certyIMEIMessage: '请补全IMEI,每组为２个IMEI',
        uploadSuccess: '上传成功！',
        uploadWarn: '请补全未填信息',
    },
};

export function getT(attr) {
    const lang = Value_Default_i18n;
    return message[lang][attr] || attr;
}
